import React, { useEffect, useState } from "react"

import OrderTable from "./UI/OneStepAllocationOrderTable";
import OrderForm from "./UI/OrderForm";
import OpenWeekend from "./UI/OneStepOpenWeekend";
import withOrder from "./withOrder";
import PaymentMessage from "./UI/PaymentMessage"

const OneStepAllocationOrderForm = props => {
  const [additionalProducts, setAdditionalProducts] = useState([])
  const [productsPath, setProductsPath] = useState(null)
  const [totalAmount, setTotalAmount] = useState(props.showOpenWeekendInfo.amount)
  const orderId = props.order.id

  useEffect(() => fetchProducts(), []);
  const [selfPickUp, setSelfPickUp] = useState(props.order.attendingOpenWeekend)

  const fetchProducts = () => {
    let ignore = false;

    fetch(`${props.additionalProductsPath}.json`, {
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    }).then(response => response.json())
      .then(response => {
        setAdditionalProducts(response.additional_products)
        setProductsPath(response.productsPath)
      });
  }
  if (props.order.status == 'eft_pending') {
    return (
      <div>
        <PaymentMessage orderStatus={props.order.status}>
          <h3>Thank you for your order</h3>
          <p>You have chosen to pay by EFT direct bank transfer.</p>
          <p>A copy of you invoice has been e-mailed to you. Our bank details and your order number are on your invoice.</p>
          <p>Please reference your order number when you perform the bank transfer so we can link your payment to your order.</p>
          <p>Payment must be received by Thursday 18th July 2024. Your order will be packed for shipping as soon as your payment is confirmed.</p>
          {props.order.attendingOpenWeekend && (
            <div>
              <p>You have chosen to pick up your order from the winery.</p>
              <p>If you are picking up your order outside of the Members Weekend days please e-mail us at <a href="mailto:winery@wdce.com.au">winery@wdce.com.au</a> or call us on 03 5433 3133 to make an appointment.</p>
              <p><b>Members Weekend Information - Friday 19th to Sunday 21st July 2024</b></p>
              <p><a href="https://wildduckcreekestate.com.au/members-open-day/">Please click here</a> and enter the ﻿Members Weekend access password﻿ <b>mrw2024</b> to make a booking. Bookings are essential for catering purposes.</p>
            </div>
          ) || (
              <div>
                <p>It will likely be sooner, however please allow up to 6 weeks for delivery.</p>
              </div>
            )}
        </PaymentMessage>
        <h2 className="text-center">Your {props.order.releaseYear.year} Order</h2>
        <OrderTable additionalProducts={additionalProducts !== null ? additionalProducts.filter(product => product.quantity !== 0) : []}
          productsPath={productsPath}
          orderId={orderId}
          {...props.order}
          setTotalAmount={setTotalAmount}
          selfPickUp={selfPickUp}
          paid={true} />
      </div>)
  } else if (props.order.status == 'paid') {
    return (
      <div>
        <PaymentMessage orderStatus={props.order.status}>
          <h3>Thank you for your order</h3>
          <p>Your credit credit card payment has been received.</p>
          <p>A copy of your invoice has been e-mailed to you.</p>
          {props.order.attendingOpenWeekend && (
            <div>
              <p>You have chosen to pick up your order from the winery.</p>
              <p>If you are picking up your order outside of the Members Weekend days please e-mail us at <a href="mailto:winery@wdce.com.au">winery@wdce.com.au</a> or call us on 03 5433 3133 to make an appointment.</p>
              <p><b>Members Weekend Information - Friday 19th to Sunday 21st July 2024</b></p>
              <p><a href="https://wildduckcreekestate.com.au/members-open-day/">Please click here</a> and enter the ﻿Members Weekend access password﻿ <b>mrw2024</b> to make a booking. Bookings are essential for catering purposes.</p>
            </div>
          ) || (
              <div>
                <p>It will likely be sooner, however please allow up to 6 weeks for delivery.</p>
              </div>
            )}
        </PaymentMessage>
        <h2 className="text-center">Your {props.order.releaseYear.year} Order</h2>
        <OrderTable additionalProducts={additionalProducts !== null ? additionalProducts.filter(product => product.quantity !== 0) : []}
          productsPath={productsPath}
          orderId={orderId}
          {...props.order}
          setTotalAmount={setTotalAmount}
          selfPickUp={selfPickUp}
          paid={true} />
      </div>)
  } else {
    return (
      <OrderForm
        order={props.order}
        handleOrderUpdate={props.updateOrder}
        {...props}
        totalAmount={totalAmount}
        selfPickUp={selfPickUp}
      >
        <h2 className="text-center">Your {props.order.releaseYear.year} {props.currentAllocationPeriod} Release</h2>
        <OrderTable
          additionalProducts={additionalProducts}
          productsPath={productsPath}
          orderId={orderId}
          handleOrderUpdate={props.updateOrder}
          selfPickUp={selfPickUp}
          {...props.order}
          setTotalAmount={setTotalAmount}
          paid={false} />

        <hr />

        <OpenWeekend
          order={props.order}
          showOpenWeekendInfo={props.showOpenWeekendInfo}
          handleAttendingOpenWeekendChange={props.updateOrder}
          setSelfPickUp={setSelfPickUp}
          {...props}
        />
      </OrderForm>
    )
  }
}

export default withOrder(OneStepAllocationOrderForm)
