import React, { useEffect, useState } from "react"
import { CSSTransition } from 'react-transition-group';

export default props => {
  const [orderDetail, setOrderDetail] = useState({})
  const [quantity, setQuantity] = useState(0)
  const [active, setActive] = useState(false)
  const [displayQuantityLimitMessage, setDisplayQuantityLimitMessage] = useState(false)
  const [increaseDisabled, setIncreaseDisabled] = useState(false)
  const [productIsOutOfStock, setProductIsOutOfStock] = useState(false)
  const [maxPerOrder, setMaxPerOrder] = useState(props.maxQuantityPerOrder)
  const [stockQuantity, setStockQuantity] = useState(null)

  useEffect(() => {
    document.addEventListener('orderUpdated', (e) => {
      let listItem = e.detail.order.orderDetails.find((orderDetail) => orderDetail.productId == props.id);

      if (listItem == undefined) {
        setActive(false)

        return
      }
      setOrderDetail(listItem || {})

      setMaxPerOrder(listItem.initialMaxQuantityPerOrder || props.maxQuantityPerOrder)
      setStockQuantity(listItem.inventoryQuantityInStock)

      if (listItem.inventoryQuantityInStock <= 0 || listItem.quantity == maxPerOrder) { setIncreaseDisabled(true)}

      if (listItem) {
        setQuantity(listItem.quantity)
        setActive(true)
      } else {
        setQuantity(0)
        setActive(false)
      }
      if (maxPerOrder < stockQuantity) {
        setProductIsOutOfStock(true)
      } else {
        setProductIsOutOfStock(false)
      }
    });
  }, []);

  useEffect(() => {
    let display = props.maxQuantityPerOrder - quantity < 30;
    setDisplayQuantityLimitMessage(displayQuantityLimitMessage || display)
  }, [quantity])

  const increaseQuantity = (e) => {
    e.preventDefault();
    ((quantity + 1) >= maxPerOrder || (stockQuantity - 1 <= 0)) ? setIncreaseDisabled(true) :  setIncreaseDisabled(false)
    if (increaseDisabled === true) { return }

    updateQuantity(quantity + 1);
    setQuantity(quantity + 1)
  }

  const decreaseQuantity = (e) => {
    e.preventDefault();
    (quantity - 1) >= maxPerOrder ? setIncreaseDisabled(true) :  setIncreaseDisabled(false)
    updateQuantity(quantity - 1)
  }

  const updateQuantity = (value) => {
    if  (value > maxPerOrder) {
      setQuantity(maxPerOrder)
      setIncreaseDisabled(true)
      return
    }
    setQuantity(value);
    props.handleAddToCart(props.id, value, props.currentOrderId)
  }

  const handleQuantityOnBlur = (e) => {
    if (quantity.toString().length == 0) {
      setQuantity(orderDetail.quantity)
    }
  }

  const productInfoFull = () => (
    <div className="container-fluid">
      {(props.itemType == "foodTable") && (
        <div className="row">
          <div className="col-12">
            <div className="product-name">
              {props.productCode}
            </div>
          </div>
        </div>
      ) || (
        <div className="row">
          {console.log(props.itemType)}
          <div className="col-12">
            <div className="product-image">
              {props.image && (
                <img src={props.image} className="img-fluid" />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <div className="product-name">
            {props.vintageYear}
          </div>
          <div className="product-name">
            {props.wineName}
          </div>
          <div className="product-name">
            {props.size}
          </div>
          <div className="price">
            {props.price}
          </div>
        </div>
      </div>
    </div>
  )

  const productInfoCondensed = () => (
    <div className="container-fluid">
      <div className="row no-gutters">
      {(props.itemType == "foodTable") && (
        <div className="col-12 text-center">
          <div className="product-name">
            {props.productCode}
          </div>
        </div>
        ) || (
          <div className="col-5 text-center">
            <div className="product-image">
              {props.image && (
                <img src={props.image} />
              )}
            </div>
          </div>
        )}
        <div className={(props.itemType == "foodTable") && "col-12" || "col-7"}>
          <div className="name-wrapper">
            <div className="product-name">
              {props.vintageYear}
            </div>
            <div className="product-name">
              {props.wineName}
            </div>
            <div className="product-name">
              {props.size}
            </div>
          </div>
          <div className="price">
            {props.price}
          </div>
        </div>
      </div>
    </div>
  )
  function quantityInfo(){

    if (productIsOutOfStock || stockQuantity == 0) {
      return outOfStock();
    } else {
      return maxQuantityPerOrderMessage();
    }

  }

  const maxQuantityPerOrderMessage = () => (
    <div className="product-max-quantity">
      Max quantity per order: <span className={(increaseDisabled === true) ? 'red' : ''}>{maxPerOrder}</span>
    </div>
  )

  const outOfStock = () => (
    <div className="product-max-quantity">
      Quantity remaining in stock: <span className='red'>{stockQuantity}</span>
    </div>
  )


  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
      <div className="card product-card text-center mb-4">
        <div className="d-flex flex-column align-items-end card-body">
          {productInfoFull()}
          {props.maxQuantityPerOrder === 0 && (
            <div className="mt-auto product-not-available">Sold Out</div>
          ) || (
            <button onClick={(e) => props.handleAddToCart(props.id, 1, props.currentOrderId)} className="mt-auto btn btn-block btn-black btn-lg text-uppercase">
              {(props.itemType == "foodTable") && "Add To Table" || "Add To Cart"}
            </button>
          )}
        </div>

        <CSSTransition
          in={active}
          timeout={300}
          className="d-flex align-items-end flex-column product-card-transition card-body"
          classNames="product-card-transition"
          unmountOnExit
        >
          <div>
            {productInfoCondensed()}
            <div className="container-fluid mt-auto">
              {(displayQuantityLimitMessage) && (
                quantityInfo()
              )}
              <div className="d-flex justify-content-center align-items-center">
                <div className="text-right">
                  <a className="btn btn-primary btn-circle" onClick={decreaseQuantity}><i className="fa fa-minus"></i></a>
                </div>
                <div className="">
                  <input type="number" maxLength="3" pattern="\d*" className={"form-control" + (increaseDisabled===true ? ' red' : '')} onBlur={handleQuantityOnBlur} onChange={(e) => updateQuantity(e.target.value)} value={quantity} />
                </div>
                <div className="text-left">
                  <a className="btn btn-primary btn-circle" onClick={increaseQuantity}><i className="fa fa-plus"></i></a>
                </div>
              </div>
              <div className="product-subtotal">
                Subtotal: <span>{orderDetail.total || "$0.00"}</span>
              </div>
              <div className="product-remove">
                <a onClick={(e) => updateQuantity(0)}>
                  {(props.itemType == "foodTable") && "Remove From Table" || "Remove From Cart"}
                </a>
              </div>
            </div>
          </div>
        </CSSTransition>

      </div>
    </div>
  );
}
