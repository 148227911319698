// if amount in stock is higher than order max quantity: display order max quantity
// if amount in stock is less than order max quantity: display amount left in stock

import React, { useEffect, useState } from "react"
import { toast } from 'react-toastify';
import { emitOrderUpdatedEvent } from './utils/events'
import ProductListItem from "./UI/ProductListItem";
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  hideProgressBar: true
})

const ShoppingCartErrorMessage = (props) => (
  <div>
    <h4>Order errors</h4>
    <ul>
      {Object.entries(props.errors).map(([key, value]) => <li><span>{key}</span> {value}</li>)}
    </ul>
  </div>
)

export default (props) => {
  const [products, setProducts] = useState(props.products);

  const addToCart = (productId, quantity) => {
    if (quantity.toString().length == 0) {
      return;
    }

    let body = new FormData();
    body.append("quantity", quantity);

    return fetch(`${props.productsPath}/${productId}`, {
      body: body,
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
    })
      .then(response => response.json())
      .then(response => {
        if (Object.keys(response.order.errors).length === 0) {
          emitOrderUpdatedEvent(response.order);
        } else {
          toast(<ShoppingCartErrorMessage {...response.order} />, { className: "shopping-cart-popup" })
        }
        return response;
      })
      .catch(error => {
        toast(<ShoppingCartErrorMessage errors={ { error: "Unexpected error occurred"} } />, { className: "shopping-cart-popup" })
      });
  }

  const productGroups = () => {
    let reducer = (groups, product) => {
      let customerGroups = product.customerGroups.includes("Cellar Door") && ["Cellar Door"] || product.customerGroups

      customerGroups.forEach(name => {
        if (!groups[name]) { groups[name] = [] }
        groups[name].push(product)
      })

      return groups;
    }

    return products.reduce(reducer, {})
  }

  return (
    <div>
      {props.products.length > 0 && (<>
        {Object.entries(productGroups()).sort((a, b) => b[0].localeCompare(a[0])).map(([groupName, products]) => (
          <div key={groupName}>
            <div className="row page-title">
              <h2>{groupName}</h2>
            </div>
            <div className="row">
              {products.map(productListItem => <ProductListItem
                  key={productListItem.id}
                  handleAddToCart={addToCart}
                  {...productListItem}
                />
              )}
            </div>
          </div>
        ))}
      </>) || (<div className="products-unavailable text-center">
        Wines for sale by the bottle are not currently available. We will notify you when the next release of wines becomes available.
      </div>)}
    </div>
  )
}